import * as React from "react";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import SoftwareIcon from "../assets/img/home-section-software-icon.svg";
import DesignIcon from "../assets/img/home-section-design-icon.svg";
import MarketingIcon from "../assets/img/home-section-marketing-icon.svg";

export const HomePageTemplate = ({
  title,
  description,
  herotext,
  software_title,
  software_content,
  software_linktext,
  design_title,
  design_content,
  design_linktext,
  marketing_title,
  marketing_content,
  marketing_linktext
}) => {
  return (
    <Layout>
      <Helmet title={title}>
        <meta name="description" content={description} />
      </Helmet>

      <div className="hero" dangerouslySetInnerHTML={{ __html: herotext }} />
      <section>
        <div className="home-sections">
          <div>
            <div>
              <img src={SoftwareIcon} />
            </div>
            <div className="home-sectionss">
              <h1 className="software">{software_title}</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: software_content }} />
            <div>
              <Link to="/software">{software_linktext}</Link>
            </div>
          </div>
          <div>
            <div>
              <img src={DesignIcon} />
            </div>
            <div className="home-sectionss">
              <h1 className="design">{design_title}</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: design_content }} />
            <div>
              <Link to="/design">{design_linktext}</Link>
            </div>
          </div>
          <div>
            <div>
              <img src={MarketingIcon} />
            </div>
            <div className="home-sectionss">
              <h1 className="marketing">{marketing_title}</h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: marketing_content }} />
            <div>
              <Link to="/marketing">{marketing_linktext}</Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

class HomePage extends React.Component<{ data }> {
  render() {
    const { frontmatter } = this.props.data.markdownRemark;
    return (
      <HomePageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        herotext={frontmatter.herotext}
        software_title={frontmatter.software_section.title}
        software_content={frontmatter.software_section.content}
        software_linktext={frontmatter.software_section.linktext}
        design_title={frontmatter.design_section.title}
        design_content={frontmatter.design_section.content}
        design_linktext={frontmatter.design_section.linktext}
        marketing_title={frontmatter.marketing_section.title}
        marketing_content={frontmatter.marketing_section.content}
        marketing_linktext={frontmatter.marketing_section.linktext}
      />
    );
  }
}

export default HomePage;

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        herotext
        software_section {
          title
          content
          linktext
        }
        design_section {
          title
          content
          linktext
        }
        marketing_section {
          title
          content
          linktext
        }
      }
    }
  }
`;
